<template>
  <StyledSelect
    class="SubtitleSelect"
    ref="select"
    v-model="value"
    :options="options"
    data-linktracking="top-nav:video-language"
  >
    <template v-slot:selected><span class="sr-only">Subtitles</span><IconSvg class="icon" /></template>
  </StyledSelect>
</template>

<script>
import StyledSelect from '@/atoms/StyledSelect.vue'
import availableLanguages from '@/assets/data/availableLanguages.json'
import IconSvg from '@/assets/img/subtitles.svg'

export default {
  name: 'SubtitleSelect',
  components: { IconSvg, StyledSelect },
  props: {
    subtitleLang: { type: String }
  },
  data () {
    return {
      value: this.subtitleLang || this.$language.current
    }
  },
  async mounted () {
    await this.$nextTick()
    this.$watch(
      () => {
        return this.$refs.select && this.$refs.select.$refs.select ? this.$refs.select.$refs.select.open : null
      },
      (open) => {
        if (!open && this.changed) {
          this.changed = false
          this.$emit('change', this.value)
          if (this.value === 'off' && this.$language.current !== 'en') {
            this.$router.replace({ params: { language: 'en' }, query: this.$route.query })
          } else if (this.value !== 'off' && this.$language.current !== this.value) {
            this.$router.replace({ params: { language: this.value }, query: this.$route.query })
          }
        }
      }
    )
  },
  computed: {
    options () {
      return [
        { value: 'off', label: this.$root.GenericLabels.disabledLabel },
        ...Object.entries(availableLanguages)
          .filter(([value]) => value !== 'ko' && value !== 'ja')
          .map(([value, label]) => ({ value, label }))
      ]
    }
  },
  watch: {
    value () {
      this.changed = true
    },
    '$language.current' () {
      if (this.$language.current !== this.value) {
        let newValue = this.$language.current
        if (newValue === 'ko' || newValue === 'ja') newValue = 'en'
        this.value = newValue
        this.$emit('change', this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.SubtitleSelect {
  display: inline-block;

  width: auto;
}

.sr-only {
  position: absolute;
  top: auto;
  left: -10000px;

  overflow: hidden;

  width: 1px;
  height: 1px;
}

.icon {
  width: rem(19px);
  height: rem(18px);
}

::v-deep {
  .v-select__menu {
    top: auto;
    right: rem(-40px);
    bottom: 115%;
    left: auto;

    width: rem(120px);

    padding: 0;

    font-size: rem(14px);
    text-align: center;
  }

  .v-select__list {
    max-height: rem(200px);
  }

  .v-select__arrow {
    display: none;
  }

  .v-select__btn {
    position: relative;

    display: flex;
    overflow: hidden;

    width: rem(35px);
    height: rem(35px);

    justify-content: center;

    padding: 0;

    border-color: $c-white;
    border-radius: rem(20px);

    background: $c-white;

    color: $c-blue;

    transform: translate(0, 1px);

    > * {
      position: relative;

      padding: 0;
    }

    &::before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;

      width: 120%;
      height: 130%;

      background: $c-blue;
      content: '';
      transform: translateY(120%) rotate(-7deg);
      transition: transform 0.3s;
    }
  }
}

.SubtitleSelect {
  html.desktop & {
    // stylelint-disable-next-line
    ::v-deep .v-select__btn:hover {
      background: $c-blue;
      color: $c-white;
      transition: color 0.3s, background-color 0s 0.3s;

      // stylelint-disable-next-line
      &::before {
        transform: translateY(-5%) rotate(0deg);
      }
    }
  }
}
</style>
