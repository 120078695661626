<template>
  <div class="wrapper full-abs">
    <div class="player_wrap full-abs" :class="{visible: isReady}">
      <div :id="id" class="player full-abs"/>
    </div>
    <transition>
      <div v-if="isLiveEvent" @click.stop.prevent class="pointer-lock full-abs"></div>
    </transition>
    <portal to="footer-right">
      <div v-if="config.noSkin" class="beforeSm:flex beforeSm:items-center beforeSm:justify-center" style="width: 100%;">
        <SubtitleSelect class="beforeSm:order-1 hideable" :class="{'hide-button': !showCustomButtons}" :subtitle-lang="subtitleLang" @change="$emit('change-subtitle', $event)"/>
        <Cta
          class="beforeSm:order-3 hideable"
          :class="{'hide-button': !showCustomButtons}"
          :icon="true"
          :title="isMuted ? $root.GenericLabels.volumeOnLabel : $root.GenericLabels.volumeOffLabel"
          :aria-label="isMuted ? $root.GenericLabels.volumeOnLabel : $root.GenericLabels.volumeOffLabel"
          @click="toggleAudio"
        >
          <template #icon>
            <VolumeOn key="VolumeOn" v-if="!isMuted" />
            <VolumeOff key="VolumeOff" v-else/>
          </template>
        </Cta>
        <Cta
          class="beforeSm:order-2 beforeSm:mx-4"
          :icon="true"
          :title="videoPlaying ? $root.GenericLabels.pauseLabel : $root.GenericLabels.playLabel"
          :aria-label="videoPlaying ? $root.GenericLabels.pauseLabel : $root.GenericLabels.playLabel"
          :data-linktracking="videoPlaying ? 'cta:streaming:pause' : 'cta:streaming:restart'"
          @click="toggleVideoState"
        >
          <template #icon>
            <PlayIcon key="PlayIcon" v-if="!videoPlaying" />
            <PauseIcon key="PauseIcon" v-else/>
          </template>
        </Cta>
      </div>
    </portal>
    <transition>
      <Loader style="color: rgba(255, 255, 255, 0.3);" v-if="!isReady" />
    </transition>
  </div>
</template>

<script>
import PlayIcon from '@/assets/img/play.svg'
import PauseIcon from '@/assets/img/pause.svg'
import VolumeOn from '@/assets/img/volume_on.svg'
import VolumeOff from '@/assets/img/volume_off.svg'
import SubtitleSelect from '@/molecules/SubtitleSelect'
import Cta from '@/atoms/Cta'

const ThronEvent = {
  READY: 'ready',
  PLAY: 'play',
  PAUSE: 'pause',
  TIME_UPDATE: 'timeupdate',
  ERROR: 'error',
  PROGRESS_STEP: 10
}

let lastMutedSetted

let triggeredStart = false

export default {
  name: 'ThronPlayer',
  components: { Cta, PlayIcon, PauseIcon, SubtitleSelect, VolumeOn, VolumeOff },
  props: {
    id: { type: String, required: true },
    config: { type: Object, required: true },
    isLiveEvent: { type: Boolean, default: false },
    showCustomButtons: { type: Boolean, default: false },
    subtitleLang: { type: String, default: '' }
  },
  data () {
    let isMuted = false
    if (lastMutedSetted !== undefined) {
      isMuted = lastMutedSetted
    } else if (this.config.muted !== undefined) {
      isMuted = this.config.muted
    }
    return {
      isMuted,
      isReady: false,
      videoPlaying: false,
      nextProgressStep: ThronEvent.PROGRESS_STEP,
      videoStepPercentage: 0
    }
  },
  mounted () {
    if (!window.THRONContentExperience) {
      const callback = window.THRONAPIReady
      // Set callback to process queue
      window.THRONAPIReady = () => {
        // Call global callback if set
        if (callback) {
          callback()
        }
        this.createPlayer()
      }
      if (!document.getElementById('THRON-script')) {
        const firstScriptTag = document.getElementsByTagName('script')[0]
        const tag = document.createElement('script')

        tag.onload = () => window.THRONAPIReady()
        tag.id = 'THRON-script'
        tag.src = `https://maserati-cdn.thron.${
          this.$language.current === 'zh' ? 'cn' : 'com'
        }/shared/ce/bootstrap/1/scripts/embeds-min.js`
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      }
    } else {
      this.createPlayer()
    }
  },
  async beforeDestroy () {
    this.isReady = false
    if (this.config.autoplay) {
      window.removeEventListener('click', this.onWindowClick, true)
    }
    await new Promise(resolve => setTimeout(() => resolve(), 250))
    this.player && this.player.destroy()
  },
  methods: {
    createPlayer () {
      this.player = window.THRONContentExperience(`#${this.id}`, {
        ...this.config,
        muted: this.isMuted,
        language: {
          interface: this.$language.current.toUpperCase(),
          content: this.$language.current.toUpperCase(),
          subTitle: this.$language.current.toUpperCase()
        }
      })
      this.player.on(ThronEvent.READY, this.onReady)
      this.player.on(ThronEvent.PLAY, this.onPlay)
      this.player.on(ThronEvent.PAUSE, this.onPause)
      this.player.on(ThronEvent.ERROR, this.onError)
      this.player.on(ThronEvent.TIME_UPDATE, this.onTimeUpdate)
      if (this.config.autoplay) {
        window.addEventListener('click', this.onWindowClick, true)
      }
    },
    async onReady () {
      this.$emit('ready')
      this.isReady = true
    },
    onPlay () {
      this.videoPlaying = true
      this.toggleAudio(!this.isMuted)
      this.$emit('play')
      if (this.isLiveEvent) {
        if (!triggeredStart) {
          triggeredStart = true
          console.log('streamstart')
          this.$root.pushAnalytics('streamstart', { pageInfo: { mediaName: 'grecale-premiere' } })
        }
      } else {
        this.$root.pushAnalytics('mediastart', { pageInfo: { mediaName: `video:${this.id}` } })
      }
    },
    onPause () {
      this.videoPlaying = false
      this.$emit('pause')
    },
    onError () {
      // console.log('🚀 ~ file: ThronPlayer.vue -> onError')
    },
    onTimeUpdate (e, time, duration) {
      if (this.isLiveEvent) {
        if (time > this.nextProgressStep) {
          this.nextProgressStep += ThronEvent.PROGRESS_STEP
          this.$root.pushAnalytics('streamprogress', { pageInfo: { mediaName: 'grecale-premiere' } })
        }
      } else if (duration) {
        const percentage = Math.floor((time / duration) * 100)
        const stepPercentage = Math.floor(percentage / 25) * 25

        if (stepPercentage !== 0 && this.videoStepPercentage !== stepPercentage) {
          this.videoStepPercentage = stepPercentage
          this.$root.pushAnalytics(
            `media${stepPercentage}`,
            { pageInfo: { mediaName: `video:${this.id}` } })
        }
      }
    },
    onWindowClick () {
      if (this.player && !this.videoPlaying) {
        this.player.play()
      }
      window.removeEventListener('click', this.onWindowClick, true)
    },
    toggleAudio (state) {
      this.isMuted = typeof state === 'boolean' ? !state : !this.isMuted
      lastMutedSetted = this.isMuted
      this.player.params({ muted: this.isMuted }, true)
      this.player.volume(this.isMuted ? 0 : 1)
    },
    toggleVideoState () {
      this.videoPlaying ? this.player.pause() : this.player.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: $c-black;
}

.hideable {
  transition: width 0s, opacity 0.2s;
}

.hide-button {
  opacity: 0;
  pointer-events: none;
}

.player {
  position: absolute;

  opacity: 0;
  transition: opacity 0.2s;

  .player_wrap.visible & {
    opacity: 1;
    transition-delay: 0.2s;
  }
}

.pointer-lock {
  z-index: 2;

  &.v-enter-active ,
  &.v-leave-active {
    transition-duration: 0.2s !important;
  }
}

::v-deep {
  .th-caption-text {
    display: none !important;
  }
}

</style>
